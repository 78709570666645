import React from "react";
import { Typography, Box } from "@mui/material";
import useHeight from "theme/layout";

const legalTexts = [
  {
    label: "Aviso legal",
    href: "/",
  },
  {
    label: "Política de privacidad",
    href: "/",
  },
  {
    label: "Consentimiento uso web",
    href: "/",
  },
];

function LegalStuffView() {
  const height = useHeight;
  return (
    <Box
      display={{ xs: "block", md: "flex" }}
      justifyContent="center"
      alignItems="center"
      m="auto"
      mb={`${height.footer}px`}
      pb={3}
      sx={{ backgroundColor: "secondary.light" }}
    >
      {legalTexts.map((legalText) => (
        <div key={legalText.label}>
          <Typography
            align="center"
            component="a"
            sx={{ textDecoration: "none", marginX: 1, color: "#555555" }}
            href={legalText.href}
            target="_blank"
          >
            {legalText.label}
          </Typography>
        </div>
      ))}
    </Box>
  );
}

export default LegalStuffView;
