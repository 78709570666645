import React from "react";
import { Grid } from "@mui/material";
import Hero from "components/Hero";
import HowItWorks from "components/HowItWorks";
import WhatIs from "components/WhatIs";
import useHeight from "theme/layout";

function HomeView() {
  const height = useHeight;
  return (
    <Grid
      container
      m="auto"
      alignItems="center"
      sx={{
        paddingTop: `${height.header}px`,
        paddingBottom: `${height.footer * 1.3}px`,
        backgroundColor: "secondary.light",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "#b8c6db",
          backgroundImage: "linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%)",
        }}
      >
        <Hero />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "#537895",
          backgroundImage: "linear-gradient(315deg, #537895 0%, #09203f 74%)",
        }}
      >
        <WhatIs />
      </Grid>
      <Grid item xs={12}>
        <HowItWorks
          sx={{
            backgroundColor: "#b8c6db",
            backgroundImage: "linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%)",
          }}
        />
      </Grid>
    </Grid>
  );
}

export default HomeView;
