import React from "react";
import { Typography, Box, Skeleton } from "@mui/material";
import HubspotForm from "react-hubspot-form";
import Config from "config/config.json";
import DownloadIcon from "@mui/icons-material/Download";

function ContentView() {
  const config = Config;
  return (
    <Box mb={2} sx={{ p: { xs: 3, md: 4 } }}>
      <Box mb={2}>
        <Box display="flex" alignItems="center">
          <DownloadIcon color="primary" sx={{ mr: 0.5 }} />
          <Typography sx={{ mb: 0 }} variant="h3">
            Descarga la aplicación
          </Typography>
        </Box>

        <Typography lineHeight="normal">
          Rellena el formulario la recibirás de inmediato
        </Typography>
      </Box>
      <Box minHeight={480}>
        <HubspotForm
          portalId={config.hubspotForm.portalId}
          formId={config.hubspotForm.formId}
          region={config.hubspotForm.region}
          onSubmit={() => console.log("Submit!")}
          onReady={(form) => console.log("Form ready!")}
          loading={
            <Box minHeight={480}>
              <Skeleton animation="wave" height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation="wave" height={60} />
            </Box>
          }
        />
      </Box>

      <Box
        height={50}
        sx={{
          backgroundColor: "whitesmoke",
          marginTop: -8,
          marginBottom: -4,
          position: "relative",
          zIndex: 9999999,
        }}
      >
        <Typography variant="body2" paragraph align="center">
          En sólo 3 segundos
        </Typography>

        {/* <Typography
          variant="body2"
          align="justify"
          sx={{
            fontSize: "11px",
            lineHeight: "1.5em",
            a: {
              color: "#232323",
              textDecoration: "none",
              cursor: "default",
            },
          }}
        >
          Ribera Salud SA necesita esta información de contacto para darte
          acceso al contenido. Puedes ejercer tus derechos de desestimiento en
          cualquier momento. Más información sobre prácticas de privacidad y
          nuestro compromiso de proteger tu privacidad en nuestra Política de
          privacidad.
        </Typography> */}
      </Box>
    </Box>
  );
}

export default ContentView;
