import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Cta from "components/Cta";

const steps = [
  {
    title: "Sube tu foto con la información sobre tu problema de piel",
    // description:
    //   "Recibe el Diagnóstico personalizado Receta online y las Indicaciones terapéuticas",
    // image: skinFaceBig,
  },
  {
    title:
      "Recibe el Diagnóstico personalizado Receta online y las Indicaciones terapéuticas",
    // description:
    //   "Te asignaremos el dermatólogo que mejor se adapte a tus necesidades y podrás enviar fotografías, que serán analizadas con inteligencia artificial.",
    // image: skinFaceBig,
  },
  {
    title: "Envío del tratamiento a domicilio o dirección destino",
    // description: "Envío del tratamiento a domicilio o dirección destino",
    // image: skinFaceBig,
  },
];

function HowItWorksView() {
  return (
    <Grid container m="auto" alignItems="center" px={{ xs: 2, md: 4 }}>
      <Grid item xs={12} md={12} py={3}>
        <Box mt={3} mb={4}>
          <Typography
            variant="h1"
            xs={{ fontSize: "80px" }}
            paragraph
            color="primary"
            align="center"
          >
            ¿Cómo funciona?
          </Typography>
        </Box>
        <Grid container spacing={6}>
          {steps.map((step, i) => (
            <>
              <Grid item xs={12} md={12 / steps.length} key={step.id}>
                <Box alignItems="flex-end" display="flex">
                  <Typography
                    sx={{
                      fontSize: 60,
                      color: "primary.main",
                      fontWeight: "bold",
                      borderWidth: 5,
                      borderRadius: "50%",
                      borderColor: "secondary.main",
                      width: 100,
                      height: 100,
                      textAlign: "center",
                      borderStyle: "solid",
                      mb: 1,
                      mx: "auto",
                    }}
                  >
                    {i + 1}
                  </Typography>
                </Box>
                <Typography
                  variant="h2"
                  color="primary"
                  paragraph
                  fontSize={26}
                  align="center"
                >
                  {step.title}
                </Typography>
                <Typography paragraph>{step.description}</Typography>
                <Cta center />
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HowItWorksView;
