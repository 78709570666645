import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import useHeight from "theme/layout";
import Cta from "components/Cta";
import VirtualDermaNetLogo from "images/VirtualDermaNetLogo.png";

function MenuView() {
  const height = useHeight;
  return (
    <AppBar
      sx={{
        top: 0,
        bottom: "auto",
        height: `${height.header}px`,
        justifyContent: "center",
        // backgroundColor: "primary.main",
        opacity: 0.9,
        color: "#fff",
        backgroundColor: "#537895",
        backgroundImage: "linear-gradient(315deg, #537895 0%, #09203f 74%)",
      }}
      component="div"
      elevation={10}
    >
      <Toolbar>
        <Box>
          <Box display="flex" alignItems="center">
            <img
              alt="logo"
              src={VirtualDermaNetLogo}
              width="40px"
              style={{ marginRight: "10px", filter: "invert(1)" }}
            />
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontWeight: "400",
              }}
              variant="h1"
              color="#fff"
            >
              VirtualDermaNet
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ opacity: 0.9 }}>
            Doble inteligencia al cuidado de tu piel
          </Typography>
        </Box>

        <Box flexGrow={1}></Box>

        <Box
          mr="60px"
          sx={{
            display: "flex",
            justifyContent: "center",
            visibility: {
              xs: "hidden",
              md: "visible",
            },
          }}
        >
          <Cta
            ctaText="Descargar"
            variant="outlined"
            buttonColor="white"
            removeMargin
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default MenuView;
