import React from "react";
import { Grid, Typography } from "@mui/material";
import mapaWithPathologies from "images/mapaWithPathologies.png";
import VirtualDermaNetApp from "images/VirtualDermaNetApp.png";

function WhatIsView() {
  return (
    <Grid
      container
      m="auto"
      alignItems="center"
      maxWidth="lg"
      minHeight={300}
      sx={{ color: "#fff" }}
      p={{ xs: 2, md: 4 }}
    >
      <Grid item xs={12} md={6}>
        <Typography align="center" variant="h2" paragraph>
          En cualquier momento
          <br />
          Desde donde estés
        </Typography>
        <Typography paragraph align="center">
          Consulta Individualizada realizada por Médicos Dermatólogos Titulados
          asistidos por los últimos avances en Inteligencia Artificial
        </Typography>
        <img
          alt="mapa"
          src={mapaWithPathologies}
          style={{ maxWidth: "90%", margin: "auto", marginBottom: 20 }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <img
          alt="mapa"
          src={VirtualDermaNetApp}
          style={{
            maxHeight: "500px",
            margin: "auto",
            display: "block",
            marginBottom: 20,
          }}
        />
        <Typography align="center" variant="h3" sx={{ mb: 2 }}>
          La solución está en tu mano
        </Typography>
      </Grid>
    </Grid>
  );
}

export default WhatIsView;
