import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import useHeight from "theme/layout";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/material/styles";
import VirtualDermaNetLogo from "images/VirtualDermaNetLogo.png";

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "50%",
  backgroundColor: "white",
  position: "absolute",
  bottom: -2,
  right: 11,
  visibility: "hidden",
  [theme.breakpoints.up("sm")]: {
    bottom: 6,
    right: 16,
    visibility: "visible",
  },
  animation: "ripple 2s infinite ease-in-out",
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "80%": {
      opacity: 0,
    },
    "100%": {
      transform: "scale(2)",
      opacity: 0,
    },
  },
}));

function FooterView() {
  const height = useHeight;
  return (
    <AppBar
      sx={{
        top: "auto",
        bottom: 0,
        height: `${height.footer}px`,
        justifyContent: "center",
        opacity: 0.9,
        backgroundColor: "#537895",
        backgroundImage: "linear-gradient(315deg, #537895 0%, #09203f 74%)",
      }}
      component="div"
      elevation={10}
    >
      <Toolbar>
        <Box>
          <Box display="flex" alignItems="center">
            <img
              alt="logo"
              src={VirtualDermaNetLogo}
              width="30px"
              style={{ marginRight: "10px", filter: "invert(1)" }}
            />
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontWeight: "400",
              }}
              variant="h2"
              color="white"
            >
              VirtualDermaNet
            </Typography>
          </Box>
          <Typography variant="body2" noWrap sx={{ opacity: 0.9 }}>
            Doble inteligencia al cuidado de la salud de tu piel
          </Typography>
        </Box>

        <Box flexGrow={1}></Box>

        <Box
          mr="60px"
          sx={{
            display: "flex",
            justifyContent: "center",
            visibility: { xs: "hidden", md: "visible" },
          }}
        >
          <Typography>¿Tienes preguntas?</Typography>
          <ChevronRightIcon />
        </Box>
        <StyledBox height={60} width={60}></StyledBox>
      </Toolbar>
    </AppBar>
  );
}

export default FooterView;
