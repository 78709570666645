import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Footer from "./components/Footer";
import LegalStuff from "./components/LegalStuff";
import Home from "./components/Home";
import Menu from "./components/Menu";
import theme from "./theme/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Menu />
        <Outlet />
        <Routes>
          <Route index path="inicio" element={<Home />} />
          <Route path="descarga" element={<Home />} />
          <Route path="*" element={<Navigate replace to="inicio" />} />
        </Routes>
        <Footer />
        <LegalStuff />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
