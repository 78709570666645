import React from "react";
import { Grid, Typography, Box } from "@mui/material";
// import AddLocationIcon from "@mui/icons-material/AddLocation";
import Cta from "components/Cta";
// import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
// import MemoryIcon from "@mui/icons-material/Memory";
import VirtualDermaNetLogofrom from "images/VirtualDermaNetLogo.png";

// const claims = [
//   {
//     text: "Más de 80 centros dermatológicos digitales en España",
//     icon: <AddLocationIcon fontSize="large" color="primary" />,
//   },
//   {
//     text: "Herramientas digitales con inteligencia artificial al servicio del paciente",
//     icon: <MemoryIcon fontSize="large" color="primary" />,
//   },
//   {
//     text: "Atención de calidad con los dermatólogos más renombrados",
//     icon: <MilitaryTechIcon fontSize="large" color="primary" />,
//   },
// ];

function HeroView() {
  return (
    <Grid
      container
      m="auto"
      alignItems="center"
      maxWidth="lg"
      minHeight={400}
      px={{ xs: 2, md: 4 }}
    >
      <Grid item xs={12} md={12} py={3}>
        <Box pt={{ xs: 0, md: 2 }} pr={{ xs: 0, md: 2 }} mb={5}>
          <Typography
            variant="h1"
            fontSize={{ xs: 38, md: 60 }}
            paragraph
            color={{ color: "black" }}
            align="center"
          >
            VirtualDermaNet
          </Typography>
          <Typography variant="h2" paragraph color="primary" align="center">
            Doble inteligencia al cuidado de la salud de tu piel
          </Typography>
          <img
            src={VirtualDermaNetLogofrom}
            alt="logo"
            style={{
              zIndex: 1,
              maxWidth: 200,
              margin: "auto",
              display: "block",
              marginBottom: 30,
            }}
            width="100%"
          />
          <Typography variant="h2" paragraph color="primary" align="center">
            Red de asistencia especializada en dermatología al servicio de los
            cuidados y salud de la piel
          </Typography>
        </Box>
        <Grid item container justifyContent="center">
          <Grid xs={12} sm={6} md={4}>
            <Typography variant="h3" color="primary" align="center">
              Consulta online
            </Typography>
            <Typography variant="h2" paragraph color="primary" align="center">
              19,90 €
            </Typography>
            <Cta center ctaText="Comprar por 19,90€" />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <Typography variant="h3" color="primary" align="center">
              Consulta online + <br />
              seguimiento primer mes
            </Typography>
            <Typography variant="h2" paragraph color="primary" align="center">
              24,90 €
            </Typography>
            <Cta center ctaText="Comprar por 24,90€" />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        mb={6}
        sx={{
          backgroundColor: "#537895",
          backgroundImage: "linear-gradient(315deg, #537895 0%, #09203f 74%)",
          boxShadow:
            "0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)",
        }}
      >
        <Box mb={3} elevation={10} p={2}>
          <Typography
            variant="h3"
            sx={{ color: "#fff" }}
            mt={3}
            paragraph
            align="center"
          >
            ¿Tienes un problema con tu piel?
          </Typography>
          <Typography
            variant="h2"
            paragraph
            align="center"
            sx={{ color: "#fff" }}
          >
            No hagas esperar a tu salud
          </Typography>
          <Cta center removeMargin />
        </Box>
      </Grid>
    </Grid>
  );
}

export default HeroView;
