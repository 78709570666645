import React, { ReactElement } from "react";
import CtaView from "./CtaView";

function Cta({
  variant,
  ctaText,
  buttonColor,
  textColor,
  removeMargin,
  center,
}): ReactElement {
  return (
    <CtaView
      ctaText={ctaText}
      buttonColor={buttonColor}
      center={center}
      textColor={textColor}
      variant={variant}
      removeMargin={removeMargin}
    />
  );
}

export default Cta;
