import React from "react";
import {
  Button,
  Typography,
  Box,
  Container,
  Modal,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import HubspotForm from "components/HubspotForm";

const StyledContainer = styled(Container)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#f5f5f5",
  borderRadius: 3,
  [theme.breakpoints.down("sm")]: {
    height: "500px",
  },
  overflow: "hidden",
}));

function CtaView({
  center,
  ctaText,
  variant,
  buttonColor,
  textColor,
  removeMargin,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Box
        mb={!removeMargin && 2}
        sx={{
          textAlign: center ? "center" : "inherit",
          mx: "auto",
          display: "block",
        }}
      >
        <Button
          variant={variant ? variant : "contained"}
          startIcon={<DownloadIcon fontSize="large" />}
          size="large"
          disableElevation
          onClick={handleOpen}
          sx={{ mx: "auto" }}
          color={buttonColor ? buttonColor : "secondary"}
        >
          <Typography color={textColor ? textColor : "inherit"}>
            {ctaText ? ctaText : "Descarga VirtualDermaNet"}
          </Typography>
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledContainer maxWidth="md" disableGutters>
          <Box
            px={2}
            py={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ backgroundColor: "secondary.main", color: "#fff" }}
          >
            <Typography variant="h6">Consigue acceso a la app</Typography>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon color="white" />
            </IconButton>
          </Box>
          <Box height="80vh" overflow="scroll">
            <HubspotForm />
          </Box>
        </StyledContainer>
      </Modal>
    </>
  );
}

export default CtaView;
